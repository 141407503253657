@import url('https://fonts.googleapis.com/css2?family=Carlito:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Serif+Georgian:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a, .link {
  color: #84b921;
  cursor: pointer;
}

body {
  margin: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-item-selected {
  background-color: #F5F5F5 !important;
  color: #333  !important;
  border-radius: 15px;
}

.menu-item-selected::before {
  content: "";
  right: 0;
  margin-top: -145px;
  height: 170px;
  width: 170px;
  position: absolute;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 350 350" width="170" height="170" xmlns="http://www.w3.org/2000/svg"><path d="M 350 150 L 350 275 L 200 275 M 200 275 C 336 275 350 275 350 150 Z" fill="%23F5F5F5"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 1000;
}

.menu-item-selected::after {
  content: "";
  right: 0;
  margin-bottom: -193px;
  height: 170px;
  width: 170px;
  position: absolute;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 350 350" width="170" height="170" xmlns="http://www.w3.org/2000/svg"><path d="M 350 150 L 350 25 L 200 25 M 200 25 C 336 25 350 25 350 150 Z" fill="%23F5F5F5"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 1000;
}

.main-menu {

}

.main-menu div.MuiListItemText-root {
  background: url("./assets/images/icons1white.svg") no-repeat;
  padding-left: 32px;
}

.menu-item-selected div.MuiListItemText-root {
  background: url("./assets/images/icons1.svg") no-repeat;
}

.main-menu-schedule div.MuiListItemText-root {
  background-position-y: -305px;
}
.main-menu-matrix div.MuiListItemText-root {
  background-position-y: -448px;
}
.main-menu-pricelist div.MuiListItemText-root {
  background-position-y: -495px;
}
.main-menu-specialists div.MuiListItemText-root {
  background-position-y: -472px;
}
.main-menu-rooms div.MuiListItemText-root {
  background-position-y: -568px;
}
.main-menu-clients div.MuiListItemText-root {
  background-position-y: -351px;
}
.main-menu-subscriptions div.MuiListItemText-root {
  background-position-y: -617px;
}

.main-menu-studios div.MuiListItemText-root {
  background-position-y: -880px;
}
.main-menu-services div.MuiListItemText-root {
  background-position-y: -544px;
}
.main-menu-statistics div.MuiListItemText-root {
  background-position-y: -593px;
}
.main-menu-users div.MuiListItemText-root {
  background-position-y: -521px;
}
.main-menu-my-profile div.MuiListItemText-root {
  background-position-y: -425px;
}
.main-menu-company div.MuiListItemText-root {
  background-position-y: -425px;
}
.main-menu-client-groups div.MuiListItemText-root {
  background-position-y: -425px; /* TODO: change icon */
}
.main-menu-support div.MuiListItemText-root {
  background-position-y: -376px;
}
.main-menu-exit div.MuiListItemText-root {
  background-position-y: -112px;
}

.MuiButton-contained {
  color: #fff !important;
  border-radius: 15px !important;
}

.MuiButton-root.SaveButton-button {
  background-color: #84b921 !important;
  border-radius: 15px !important;
}

.dx-scheduler-appointment {
  box-sizing: content-box;
  padding-right: 15px;
}
.dx-scheduler-timeline .dx-scheduler-appointment,
.dx-scheduler-work-space-month .dx-scheduler-appointment,
.dx-scheduler-all-day-appointment .dx-scheduler-appointment {
  box-sizing: border-box;
  padding-right: 0;
}

.MainLayout-relativeContainer .Container-container > div {
  width: calc(14.25% - 2px) !important;
}

/* Hide Page size in grid */
.css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-selectLabel {
  display: none !important;
}

.strike {
  text-decoration: line-through;
}

.client-card-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.client-card-row svg {
  margin-right: 10px;
}
